<template>
    <main>
        <page-hero site="Administracja"/>

        <section class="admins-section">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 about-us">
                        <h3 class="section-title">Trochę o nas</h3>
                        <p v-html="cfg.landingPage.home.aboutServer"></p>
                    </div>
                </div>

                <div class="team">
                    <div class="row">
                        <div class="col-xl-12 ">
                            <h3 class="section-title">Skład zespołu</h3>
                        </div>
                    </div>

                    <div v-if="loading">
                        <div class="spinner my-5">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row">
                            <div class="col-xl-12 group-pills">
                                <div v-for="adminGroup in adminGroups" :key="adminGroup.sgid" :class="[activeGroup === adminGroup.sgid ? 'active' : '']"
                                     class="pill" @click="setActiveGroup(adminGroup.sgid)">{{ adminGroup.name }}
                                </div>
                            </div>
                        </div>

                        <transition-group class="row admins-list" name="admins" tag="div">
                            <div v-for="admin in showedAdmins" :key="admin.dbid" :class="[admin.type ? 'no-admin' : '']"
                                 class="col-xl-3 single-admin">
                                <div v-if="!admin.type" class="card">
                                    <div class="card-header">
                                        <client-avatar :dbid="admin.dbid" :size="100" class="client-avatar"/>
                                    </div>
                                    <div class="card-body">
                                        <h4><client :client="admin" center/></h4>
                                    </div>
                                    <div class="card-footer">
                                        <span class="admin-group">{{ getAdminGroup(admin.group).name }}</span>
                                        <span :class="[admin.status === 'online' ? 'online' : (admin.status === 'offline' ? 'offline' : 'away')]"
                                              class="admin-status">{{
                                                admin.status
                                            }}</span>
                                    </div>
                                </div>
                                <h4 v-else>Brak adminów</h4>
                            </div>
                        </transition-group>
                    </div>
                </div>

            </div>
        </section>
    </main>
</template>

<script>

import MainService from "../services/main.service";

export default {
    name: "Administration",
    data() {
        return {
            loading: true,
            admins: [],
            adminGroups: [
                 {
                    name: 'Wszyscy',
                    sgid: 0
                }
            ],
            activeGroup: 0,
            showedAdmins: []
        }
    },
    computed: {
        cfg() {
            return this.$store.getters.cfg;
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.loading = true;

            MainService.getAdmins().then(
                response => {
                    response.data.forEach(info => {
                        this.adminGroups.push(info.group);

                        info.clients.forEach(client => {
                            this.admins.push({
                                ...client,
                                group: info.group.sgid
                            })
                        })
                    });
                    this.showedAdmins = this.admins.filter(() => true);
                    this.loading = false;
                },
                error => {
                    console.log(error);
                    this.loading = false;
                }
            )
        },
        setActiveGroup(adminGroup) {
            if (adminGroup === this.activeGroup)
                return;

            this.activeGroup = adminGroup;

            if (adminGroup === 0)
                this.showedAdmins = this.admins.filter(() => true);
            else {
                this.showedAdmins = this.showedAdmins.sort((a, b) => {
                    if (a.group === adminGroup && b.group === adminGroup)
                        return 0;
                    else if (a.group === adminGroup)
                        return -1;
                    else
                        return 1;
                });

                setTimeout(this.filterAdmins, 10);
            }
        },
        filterAdmins() {
            this.showedAdmins = this.admins.filter(admin => admin.group === this.activeGroup || this.activeGroup === 0);

            if (this.showedAdmins.length === 0)
                this.showedAdmins.push({nick: "none", type: "noneAdmins"});
            else
                this.showedAdmins = this.showedAdmins.filter(admin => !admin.type);
        },
        getAdminGroup(sgid) {
            return this.adminGroups.find(g => g.sgid == sgid);
        }
    },

}
</script>

<style scoped>

</style>