import { render, staticRenderFns } from "./Administration.vue?vue&type=template&id=15e4ebcb&scoped=true&"
import script from "./Administration.vue?vue&type=script&lang=js&"
export * from "./Administration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15e4ebcb",
  null
  
)

export default component.exports